.ReviewList__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReviewList__title > * {
  margin-right: 8px;
}

.ReviewList__message {
  margin: 40px 0 24px;
}

.ReviewList__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.ReviewList__buttons > * {
  width: 176px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.ReviewList__shareIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.ReviewList__editor {
  flex-direction: column;
}

.ReviewList__input {
  margin: 16px 0;
}

.ReviewList__confirmation {
  margin: 16px 0;
}

.ReviewList__submitButton {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 16px 0;
}

@media (max-width: 720px) {
  .ReviewList__buttons > * {
    width: 100%;
    margin-right: 0;
  }
}