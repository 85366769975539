.ProductPromise {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: solid 1px #2223;
  border-radius: 16px;
  padding: 24px;
}

.ProductPromise__icon {
  width: 24px;
  min-width: 24px;
  margin: 4px 16px 0 0;
  color: #ff7417;
}

.ProductPromise__heading {
  margin-bottom: 8px;
}

.ProductPromise__link {
  color: #222;
  font-weight: 600;
}

.ProductPromise__link:hover {
  opacity: 0.5;
}

@media (max-width: 720px) {
  .ProductPromise {
    padding: 24px 0;
    border: none;
    border-top: solid 1px #2223;
    border-bottom: solid 1px #2223;
    border-radius: 0;
  }
}