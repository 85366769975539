.DashSettings__head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.DashSettings__actions > * {
  margin: 8px;
}

.DashSettings__block {
  display: flex;
  flex-direction: row;
  margin: 24px 0;
}

.DashSettings__heading {
  flex: 1;
  margin-bottom: 16px;
}

.DashSettings__description {
  margin: 16px 8px;
}

.DashSettings__field {
  flex: 3;
}

.DashSettings__input {
  margin: 8px;
}

.DashSettings__inputRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DashSettings__inputRow > * {
  flex: 1;
}

@media (max-width: 720px) {
  .DashSettings__block {
    flex-direction: column;
  }

  .DashSettings__heading {
    flex: 0;
  }

  .DashSettings__field {
    flex: 0;
  }

  .DashSettings__inputRow {
    flex-direction: column;
  }

  .DashSettings__inputRow > * {
    width: 100%;
  }
}
