.LinkButton {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 8px;
  margin: 0 -8px;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  color: #222;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  text-decoration: underline;
  transform: scale(1);
  transition: transform 50ms;
}

.LinkButton:focus,
.LinkButton:hover {
  background: #2221;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.LinkButton:active {
  transform: scale(0.95);
}
