.ProductCollaborators__collaborator {
  margin: 8px 0;
}

.ProductCollaborators__inviteCollaborators {
  width: 100%;
  margin-top: 8px;
}

.ProductCollaborators__collaboratorSearch {
  margin-bottom: 216px;
}
