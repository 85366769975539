.OrderTracking {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.OrderTracking__url {
  color: inherit;
  margin: 8px 8px 8px 0;
  word-break: break-all;
}

.OrderTracking__url:hover {
  opacity: 0.5;
}
