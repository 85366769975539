.Theorist__bannerLink {
  margin: 0 4px;
  color: #222;
  font-weight: 600;
}

.Theorist__bannerLink:hover {
  opacity: 0.5;
}

.Theorist__profilePicture {
  width: 128px;
  height: 128px;
  margin: -64px 24px 8px;
}

.Theorist__gallery {
  position: relative;
}

.Theorist__viewInstagram {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.Theorist__viewInstagramText {
  margin-left: 8px;
  font-weight: 600;
}

.Theorist__tabs {
  position: sticky;
  top: 0;
  z-index: 10;
  margin: 0 -24px;
  padding: 0 24px;
  background: #fff;
}

.Theorist__emptyMessage {
  margin: 40px 0;
}

.Theorist__section {
  margin-top: 40px;
  padding-top: 40px;
  border-top: solid 1px #2223;
}

.Theorist__about {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.Theorist__profileCard {
  margin: 40px 40px 0 0;
}

.Theorist__starIcon {
  width: 16px;
  height: 16px;
}

.Theorist__aboutInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  margin: 24px 0;
}

.Theorist__contact {
  margin-top: 24px;
}

@media (max-width: 1080px) {
  .Theorist__profilePicture {
    width: 96px;
    height: 96px;
    margin-top: -48px;
  }
}

@media (max-width: 720px) {
  .Theorist__profilePicture {
    margin: -48px 16px 8px;
  }

  .Theorist__viewInstagram {
    bottom: 16px;
    right: 16px;
  }

  .Theorist__viewInstagramText {
    display: none;
  }

  .Theorist__about {
    flex-direction: column;
  }

  .Theorist__profileCard {
    margin-right: 0;
  }

  .Theorist__contact {
    width: calc(100vw - 48px);
  }
}
