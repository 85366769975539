.TextArea {
  padding: 16px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 8px;
  border: 0;
  outline: 0;
  resize: none;
  box-shadow: inset 0 0 0 1px #2223;
  box-sizing: border-box;
}
