.DashPriceInput {
  position: relative;
}

.DashPriceInput__input {
  margin-left: 24px;
}

.DashPriceInput__currency {
  position: absolute;
  left: 16px;
  bottom: 12px;
  opacity: 0.5;
}
