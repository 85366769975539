.Tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 24px;
  cursor: pointer;
}

.Tab__button {
  min-height: 48px;
  padding: 0;
  margin: 4px 0;
  font-size: 16px;
  font-weight: 600;
  background: none;
  border: 0;
  outline: 0;
  opacity: 0.5;
  transform: scale(1);
  transition: opacity 50ms, transform 50ms;
}

.Tab__underline {
  width: 100%;
  height: 2px;
  background: #222;
  opacity: 0;
  transition: opacity 50ms;
}

.Tab:hover:not(.Tab--selected) .Tab__underline {
  opacity: 0.2;
}

.Tab:active .Tab__button {
  transform: scale(0.95);
}

.Tab:hover .Tab__button,
.Tab--selected > *, {
  opacity: 1;
}

.Tab:not(.Tab--selected) .Tab__button {
  cursor: pointer;
}

.TabGroup {
  box-shadow: inset 0 -1px 0 #2223;
}

.TabGroup__content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TabGroup--pill {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: none;
}

.TabGroup--pill .TabGroup__content {
  height: 40px;
  border-radius: 20px;
  background: #2221;
}

.TabGroup--pill .Tab {
  margin-right: 0;
}

.TabGroup--pill .Tab__button {
  height: 32px;
  min-height: 32px;
  padding: 8px 16px;
  margin: 4px;
  border-radius: 16px;
  font-size: 14px;
  opacity: 1;
}

.TabGroup--pill .Tab:hover:not(.Tab--selected) .Tab__button {
  background: #fffb;
}

.TabGroup--pill .Tab--selected .Tab__button {
  background: #fff;
  box-shadow: inset 0 0 0 1px #2221, 0 1px 2px #2221 !important;
}

.TabGroup--pill .Tab__underline {
  display: none;
}
