.ProductInventory--disabled {
  opacity: 0.5;
}

.ProductInventory__count {
  height: 24px;
  padding: 0 4px;
  border-radius: 4px;
  background: #fce20588;
}

.ProductInventory__count--zero {
  background: #b2222288;
}
