.Drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  background: rgba(0,0,0,0.5);
  opacity: 1;
}

.Drawer--right {
  flex-direction: row-reverse;
}

.Drawer--closed {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s 0.3s;
}

.Drawer__overlay {
  flex: 1;
}

.Drawer__content {
  transform: translateX(0);
  transition: transform 0.3s;
}

.Drawer--closed .Drawer__content {
  transform: translateX(-100%);
}

.Drawer--closed.Drawer--right .Drawer__content {
  transform: translateX(100%);
}
