.ImageSelector {
  display: flex;
  flex-direction: row;
}

.ImageSelector__featured {
  flex: 1
}

.ImageSelector__featuredImage {
  width: 100%;
  padding-bottom: 125%;
}

.ImageSelector__featuredImage--square {
  padding-bottom: 100%;
}

.ImageSelector__gallery {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 16px;
  max-height: 600px;
  overflow-y: auto;
}

.ImageSelector__galleryImage {
  margin: 8px;
  border: solid 2px #0000;
  opacity: 0.5;
  cursor: pointer;
}

.ImageSelector__galleryImage:hover {
  opacity: 1;
}

.ImageSelector__galleryImage--clicked {
  opacity: 1;
  border: solid 2px #222;
}

.ImageSelector__spacer {
  width: 40px;
  height: 16px;
}

@media (max-width: 720px) {
  .ImageSelector {
    flex-direction: column;
  }

  .ImageSelector__gallery {
    flex-direction: row;
    margin: 8px 0 0 0;
    max-width: calc(100vw - 48px);
    overflow-x: auto;
  }
}
