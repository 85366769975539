.ProductFilters__adminContainer {
  margin-bottom: 32px;
}

.ProductFilters__adminTitle {
  margin-bottom: 16px;
}

.ProductFilters__message {
  margin: 8px 0 16px;
}

.ProductFilters__subSelection {
  border-top: solid 1px #2223;
  margin: 16px 0;
  padding-top: 16px;
}

.ProductFilters__subSelectionTitle {
  margin-bottom: 16px;
}

.ProductFilters__checkbox {
  margin: 8px 0;
}
