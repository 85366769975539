.OrderPipeline {
  padding: 24px;
  border-radius: 12px;
  background: #2221;
}

.OrderPipeline__heading {
  margin: 16px 0 8px;
}

.OrderPipeline__package {
  margin-top: 40px;
}

.OrderPipeline__stepCreator {
  margin: 16px 0;
}