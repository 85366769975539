.OrderStatus {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.OrderStatus__status {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
  padding: 0 12px;
  margin: 4px;
  border-radius: 12px;
  background: #2224;
  text-transform: capitalize;
  white-space: nowrap;
}

.OrderStatus__status--green {
  background: #00a86b44;
}

.OrderStatus__status--blue {
  background: #0f52ba44;
}

.OrderStatus__status--gray {
  background: #22222244;
}

.OrderStatus__status--yellow {
  background: #fce20544;
}

.OrderStatus__statusCount {
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #222222;
  color: #fff;
}
