.ProductOrder {
  display: flex;
  flex-direction: column;
}

.ProductOrder__row {
  display: flex;
  flex-direction: row;
}

.ProductOrder__quantity {
  margin-right: 24px;
}

.ProductOrder__button {
  flex: 1;
}

.ProductOrder__secondaryButton {
  margin-top: 24px;
}
