.Gallery {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 320px;
  border-radius: 12px;
  overflow: hidden;
}

.Gallery--empty {
  background: #2221;
}

.Gallery > *:not(:first-child) {
  margin-left: 12px;
}

.Gallery__box {
  display: flex;
  flex-direction: column;
}

.Gallery__box > * {
  width: 100%;
  height: 100%;
}

.Gallery__box > *:not(:first-child) {
  margin-top: 12px;
}

.Gallery__box--double {
  flex: 2;
}

.Gallery__box--single {
  flex: 1;
}

@media (max-width: 720px) {
  .Gallery > *:not(:first-child) {
    margin-left: 8px;
  }

  .Gallery__box > *:not(:first-child) {
    margin-top: 8px;
  }
}
