.Cart {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background: #fff;
}

.Cart__topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 24px;
  border-bottom: solid 1px #2223;
}

.Cart__body {
  flex: 1;
  padding: 40px 24px;
  overflow-y: auto;
}

.Cart__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 40px;
  border-top: solid 1px #2223;
}

.Cart__body > * {
  width: 100%;
}

.Cart__emptyMessage {
  margin-bottom: 24px;
}

.Cart__total {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.Cart__checkoutButton {
  width: 100%;
}

.Cart__deliveryMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.Cart__deliveryIcon {
  margin-right: 16px;
}

.Cart__lineItem {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  margin-bottom: 24px;
  border-bottom: solid 1px #2223;
}

.Cart__lineItemTop,
.Cart__lineItemBottom {
  display: flex;
  flex-direction: row;
}

.Cart__lineItemTop {
  align-items: flex-start;
  margin-bottom: 24px;
}

.Cart__lineItemBottom {
  align-items: center;
  justify-content: space-between;
}

.Cart__lineItemBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin: 0 24px;
}

.Cart__lineItemLink {
  color: inherit;
  text-decoration: none;
}

.Cart__lineItemLink:hover {
  text-decoration: underline;
}

.Cart__lineItemNotes {
  margin-bottom: 24px;
}

.Cart__lineItemQuantity {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 8px;
  border: solid 1px #2223;
}
