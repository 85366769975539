.DashDrawer {
  display: flex;
  flex-direction: column;
  width: 240px;
  padding: 16px 8px;
  box-sizing: border-box;
}

.DashDrawer__logout {
  margin: 24px 8px;
}

.DashDrawerLink {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin: 4px 0;
  padding: 0 8px;
  border-radius: 4px;
  text-decoration: none;
  color: #222;
}

.DashDrawerLink:hover,
.DashDrawerLink:focus {
  background: #2221;
}

.DashDrawerLink--active {
  background: #2221;
  color: #ff7417;
}

.DashDrawerLink--active .DashDrawerLink__indicator {
  position: absolute;
  left: -8px;
  width: 4px;
  height: 40px;
  border-radius: 2px;
}

.DashDrawerLink--active .DashDrawerLink__indicator {
  background: #ff7417;
}

.DashDrawerLink__icon {
  width: 32px;
  margin-right: 12px;
}
