.ProfileCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(100vw - 48px);
  min-width: 0;
  &, & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ProfileCard__main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ProfileCard__image {
  position: relative;
}

.ProfileCard__eliteIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #ff7417;
}

.ProfileCard__text {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  flex: 1;
  &, & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ProfileCard__subtitleHighlight {
  opacity: 0.5;
}

.ProfileCard__subtitleStar {
  width: 12px;
  height: 12px;
  color: #fda50f;
}

.ProfileCard--large {
  min-width: 256px;
  padding: 24px;
  border-radius: 24px;
  box-shadow: 0 4px 16px rgba(0,0,0,0.2) !important;
  box-sizing: border-box;
}

.ProfileCard--large .ProfileCard__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.ProfileCard--large .ProfileCard__text {
  margin-left: 0;
  margin-top: 8px;
  text-align: center;
  max-width: calc(50vw - 256px);
}

.ProfileCard__highlights {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.ProfileCard__highlight {
  width: 104px;
  padding: 8px 0;
  border-top: solid 1px #2223;
}

.ProfileCard__highlight:first-child {
  border-top: none;
}

.ProfileCard--link {
  position: relative;
  padding: 8px;
  margin: -8px;
  color: #222;
  text-decoration: none;
  cursor: pointer;
  transform: scale(1);
  transition: transform 50ms;
}

.ProfileCard--link > .ProfileCard__overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 12px;
  background: #2221;
  opacity: 0;
  transition: opacity 100ms;
}

.ProfileCard--large > .ProfileCard__overlay {
  border-radius: 24px;
}

.ProfileCard--link:hover > .ProfileCard__overlay {
  opacity: 1;
}

.ProfileCard--link:active {
  transform: scale(0.95);
}

.ProfileCard:not(.ProfileCard--link) .ProfileCard__subtitle {
  opacity: 0.5;
}

@media (max-width: 720px) {
  .ProfileCard--large .ProfileCard__text {
    max-width: calc(100vw - 224px);
  }
}