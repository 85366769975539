.LineItemGroup__lineItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 24px;
}

.LineItemGroup__lineItemBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-left: 24px;
}

.LineItemGroup__lineItemBodyTop {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.LineItemGroup__lineItemTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.LineItemGroup__lineItemLink {
  margin-top: 4px;
  color: inherit;
  text-decoration: none;
}

.LineItemGroup__lineItemLink:hover {
  text-decoration: underline;
}

.LineItemGroup__lineItemUnitPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 96px;
  min-width: 96px;
}

.LineItemGroup__lineItemTotalPrice {
  display: flex;
  flex-direction: row-reverse;
  width: 72px;
  min-width: 72px;
  margin-left: 24px;
}

.LineItemGroup__lineItemInfo {
  margin-top: 8px;
}

.LineItemGroup__reviewRating {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.LineItemGroup__reviewRatingStar {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  color: #2223;
}

.LineItemGroup__reviewRatingStar--active {
  color: #fda50f;
}

.LineItemGroup__lineItemNotes {
  margin-bottom: 24px;
}


@media (max-width: 720px) {
  .LineItemGroup__lineItemBodyTop {
    flex-direction: column;
  }

  .LineItemGroup__lineItemUnitPrice {
    margin-top: 8px;
    justify-content: flex-start;
  }
}
