.Button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  transform: scale(1);
  transition: transform 50ms;
}

.Button--slim {
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
}

.Button--primary {
  background: linear-gradient(#fda50f, #ff7417);
}

.Button--primary:active {
  background: #fda50f;
}

.Button--secondary {
  background: #222;
}

.Button--secondary:hover {
  background: #222;
}

.Button--tertiary {
  background: #fff;
  color: #222;
  border: solid 1px #222;
}

.Button--tertiary:hover {
  background: #eee;
}

.Button--danger {
  background: #b22222;
}

.Button--danger:active {
  background: #822;
}

.Button--warn {
  background: #fff;
  color: #b22222;
  border: solid 1px #b22222;
}

.Button--warn:hover {
  background: #fee;
}

.Button:active {
  transform: scale(0.95);
}

.Button:disabled {
  background: #2228;
  cursor: not-allowed;
}
