.ProductItem {
  display: flex;
  flex-direction: column;
  color: #222;
  text-decoration: none;
  cursor: pointer;
}

.ProductItem__image {
  width: 100%;
  padding-bottom: 125%;
  margin-bottom: 16px;
}

.ProductItem__image--square {
  padding-bottom: 100%;
}

.ProductItem__image--soldOut {
  opacity: 0.5;
}

.ProductItem__highlight {
  margin-bottom: 8px;
}

.ProductItem__titleLine {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
}

.ProductItem__title {
  flex: 1;
}

.ProductItem__rating {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ProductItem__ratingStar {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  color: #fda50f;
}

.ProductItem__subtitle {
  margin-bottom: 16px;
}

.ProductItem__price {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ProductItem__priceFrom {
  margin-right: 6px;
}
