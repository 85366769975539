.DesignOrder {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  padding: 40px 24px;
  border-radius: 12px;
  background: #2221;
}

.DesignOrder__header {
  display: flex;
  flex-direction: row;
}

.DesignOrder__image {
  width: 80px;
  min-width: 80px;
  height: 100px;
  margin-right: 16px;
}

.DesignOrder__headerText {
  display: flex;
  flex-direction: column;
}

.DesignOrder__title {
  margin-bottom: 8px;
}

.DesignOrder__lineItems {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}

.DesignOrder__lineItem {
  margin: 8px 0;
}

.DesignOrder__button {
  width: 300px;
  margin: 24px 0;
}

@media (max-width: 720px) {
  .DesignOrder {
    margin: 40px -24px;
    border-radius: 0;
  }

  .DesignOrder__button {
    width: 100%;
  }
}
