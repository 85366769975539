.Counter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Counter__button {
  width: 32px;
  height: 32px;
  color: #222;
  border: solid 1px #222;
  opacity: 0.5;
}

.Counter__button:hover,
.Counter__button:active {
  opacity: 1;
}

.Counter__button:disabled {
  opacity: 0.2;
}

.Counter__value {
  width: 16px;
  margin: 0 16px;
  text-align: center;
}
