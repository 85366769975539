.Dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  opacity: 1;
}

.Dialog--closed {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s 0.3s;
}


.Dialog__content {
  width: 400px;
  max-width: 400px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 16px #0008;
  box-sizing: border-box;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.3s;
}

.Dialog--closed .Dialog__content {
  opacity: 0;
  transform: translateY(40px);
}

.Dialog__topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

@media (max-width: 720px) {
  .Dialog {
    justify-content: flex-end;
  }

  .Dialog__content {
    width: 100%;
    border-radius: 12px 12px 0 0;
  }

  .Dialog--closed .Dialog__content {
    transform: translateY(100vh);
  }
}
