.TheoristInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TheoristInfo__stats {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TheoristInfo__stat {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.TheoristInfo__icon {
  width: 16px;
  margin-right: 8px;
}

.TheoristInfo__divider {
  margin: 0 8px;
}

.TheoristInfo__elite {
  width: 12px;
  margin-right: 8px;
  color: #ff7417;
}

.TheoristInfo__star {
  width: 12px;
  margin: 0 8px 0 4px;
  color: #fda50f;
}

.TheoristInfo__pinnedNote {
  margin: 24px 0;
  padding: 24px;
  border-radius: 12px;
  border: solid 1px #2223;
  background: rgba(15, 82, 186, 0.2);
}

.TheoristInfo__iconText {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  padding-bottom: 16px;
  border-bottom: solid 1px #2223;
}

@media (max-width: 720px) {
  .TheoristInfo__stats {
    flex-direction: column;
    align-items: flex-start;
  }

  .TheoristInfo__divider:first-child {
    display: none;
  }
}
