.ColorOption {
  position: relative;
  width: 40px;
  height: 40px;
  background: none;
  border: solid 2px #2220;
  border-radius: 20px;
  cursor: pointer;
}

.ColorOption:hover:not(.ColorOption--selected) {
  border: solid 2px #2223;
}

.ColorOption--selected {
  border: solid 2px #0f52ba;
}

.ColorOption--disabled {
  opacity: 0.5;
}

.ColorOption__fill {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border: solid 1px #2223;
  border-radius: 16px;
}

.ColorOption__overlay {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 16px;
  background: radial-gradient(circle farthest-corner at 40% 10%, #fff3, #fff0, #2223);
}

.ColorGroup {
  display: flex;
  flex-direction: column;
}

.ColorGroup__options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ColorGroup__options > * {
  margin: 0 8px 8px 0;
}

.ColorGroup__label {
  margin-top: 8px;
}
