.ProductOptions__select {
  width: 100%;
  margin: 40px 0;
}

.ProductOptions__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.ProductOptions__label > * {
  margin-right: 8px;
}

.ProductOptions__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  margin: 0 8px 8px 0;
  font-size: 14px;
  color: #222;
  background: #fff;
  border: solid 2px #2223;
  border-radius: 8px;
  cursor: pointer;
}

.ProductOptions__item:hover:not(.ProductOptions__item--selected) {
  border: solid 2px #222;
}

.ProductOptions__item--selected {
  border: solid 2px #0f52ba;
}

.ProductOptions__item--selected {
  font-weight: 600;
}

.ProductOptions__color--disabled,
.ProductOptions__item--disabled {
  opacity: 0.5;
}
