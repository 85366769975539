.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Login__box {
  width: 600px;
  padding: 32px;
  border-radius: 12px;
  border: solid 1px #2228;
  box-sizing: border-box;
}

.Login__input {
  width: 100%;
  margin: 24px 0;
}

.Login__error {
  margin-top: 24px;
  color: #c03;
}

.Login__button {
  width: 100%;
}

.Login__forgotPassword {
  margin-top: 16px;
}

@media (max-width: 720px) {
  .Login__box {
    width: 100%;
    padding: 0;
    border: none;
  }
}
