.AppImage {
  position: relative;
  overflow: hidden;
  background: #2223;
}

.AppImage__image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.AppImage--loaded {
  background: #fff;
}

.AppImage--bordered {
  border: solid 1px #2223;
}

.AppImage--circular {
  border-radius: 100%;
}

.AppImage--elevated {
  box-shadow: 0 2px 8px #2228;
}
