.Order__date {
  margin: 8px 0 24px;
}

.Order__reviewEditor {
  margin-top: 40px;
}

.Order__feedback {
  width: 100%;
  padding-top: 40px;
  margin-top: 40px;
  border-top: solid 1px #2223;
}

.Order__feedbackInput {
  width: 100%;
  height: 128px;
  margin: 24px 0;
}

.Order__reviewThanks {
  margin: 24px 0;
}
