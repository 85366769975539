.DesignModule__button {
  width: 100%;
  margin-top: 24px;
}

.DesignProductList__module {
  margin-top: 0;
}

.DesignDye {
  display: flex;
  flex-direction: column;
}

.DesignDye__options {
  margin-top: 8px;
}

.DesignDye__buttonWrapper {
  padding: 2px;
  background: linear-gradient(#b22222, #ff7417, #fda50f, #00a86b, #0f52ba);
  border-radius: 20px;
}

.DesignDye__button {
  height: 36px;
  border: none;
  border-radius: 18px;
}

.DesignDye__price {
  margin-top: 8px;
}
