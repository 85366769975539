.Carousel {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
}

.Carousel__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Carousel__titles {
  flex: 1;
  margin-bottom: 8px;
}

.Carousel__title {
  margin-bottom: 8px;
}

.Carousel__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.Carousel__showButton {
  margin: 0 8px 8px 8px;
}

.Carousel__arrowButton {
  width: 32px;
  height: 32px;
  padding: 12px;
  border: solid 1px #2223;
  margin: 0 0 8px 8px;
  transform: scale(1);
  transition: transform 50ms;
}

.Carousel__arrowButton:hover {
  background: #0000;
  transform: scale(1.05);
}

.Carousel__arrowButton:active {
  background: #0000;
}

.Carousel__listWrapper {
  position: relative;
}

.Carousel__bleed {
  position: absolute;
  top: 0;
  bottom: 0;
}

.Carousel__bleed--left {
  left: 0;
  background: linear-gradient(to right, #fff, #fff0);
}

.Carousel__bleed--right {
  right: 0;
  background: linear-gradient(to left, #fff, #fff0);
}

.Carousel__item {
  width: 100%;
}
