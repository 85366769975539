.PaymentInfo__sectionTop {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px #2223;
}

.PaymentInfo__sectionBottom {
  width: 100%;
  padding-top: 16px;
  margin-top: 16px;
  border-top: solid 1px #2223;
}

.PaymentInfo__line {
  margin: 8px 0;
}
