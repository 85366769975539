.Pill {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 18px;
  background: none;
  border: solid 1px #2223;
  outline: none;
  cursor: pointer;
}

.Pill--active {
  background: #222;
  color: #fff;
}

.Pill:not(.Pill--active):hover {
  border: solid 1px #222;
}
