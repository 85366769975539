.DashHome__quickView {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.DashHome__profileIcon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.DashHome__eliteIcon {
  width: 12px;
  margin: 0 8px 0 16px;
  color: #ff7417;
}

.DashHome__section {
  margin: 40px 0;
}
