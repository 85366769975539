.ProductStatus {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 12px;
  background: #2224;
  text-transform: capitalize;
}

.ProductStatus--active {
  background: #00a86b44;
}

.ProductStatus--draft {
  background: #0f52ba44;
}

.ProductStatus--pending {
  background: #fce20544;
}
