.Checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.Checkbox--disabled {
  cursor: not-allowed;
}

.Checkbox__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
  margin-right: 16px;
  background: none;
  color: #fff;
  border: solid 1px #222;
  border-radius: 6px;
  opacity: 0.5;
  cursor: pointer;
}

.Checkbox:hover .Checkbox__box {
  opacity: 1;
}

.Checkbox--disabled:hover .Checkbox__box {
  opacity: 0.5;
}

.Checkbox__box--checked {
  background: #222;
  opacity: 1;
}

.Checkbox__icon {
  width: 12px;
}

.Checkbox__box:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
