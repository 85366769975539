.Input {
  display: flex;
  flex-direction: column;
}

.Input__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 64px;
  border: solid 1px #2228;
  border-radius: 8px;
}

.Input__container:focus-within {
  border: solid 1px #222;
  box-shadow: #222 0 0 0 1px inset !important;
}

.Input__container--collapse {
  height: 48px;
}

.Input__container--disabled {
  background: #2221;
  border: solid 1px #2223;
  cursor: not-allowed;
}

.Input__container--error {
  background: #b222220b;
  border: solid 1px #c03;
}

.Input__container--error:focus-within {
  border: solid 1px #c03;
  box-shadow: #c03 0 0 0 1px inset !important;
}

.Input__label {
  margin: 0 16px;
}

.Input__container--disabled .Input__label {
  opacity: 0.5;
}

.Input__input {
  margin: 0 8px;
  padding: 8px;
  font-size: 16px;
  background: 0;
  border: 0;
  border-radius: 8px;
  outline: 0;
}

.Input__container--disabled .Input__input {
  cursor: not-allowed;
}

.Input__input::placeholder {
  color: #222;
  opacity: 0.5;
}

.Input__input:-ms-input-placeholder {
  color: #2228;
}

.Input__input::-ms-input-placeholder {
  color: #2228;
}

.Input__error {
  margin-top: 8px;
  color: #b22222;
}

.Input__error--gone {
  display: none;
}
