.Blog {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Blog__publishDate {
  margin-top: 16px;
}

.Blog__author {
  display: inline-flex;
  margin: 24px 0;
}

.Blog__content {
  max-width: 720px;
}