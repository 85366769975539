.ShopComplexFilters {
  position: sticky;
  top: 80px;
  width: 240px;
  min-width: 240px;
  max-height: calc(100vh - 80px);
  padding-top: 40px;
  margin-right: 40px;
  background: #fff;
  opacity: 1;
  transition: opacity 0.2s;
  overflow-y: auto;
}

.ShopComplexFilters--hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0s;
}

.ShopComplexFilters__header,
.ShopComplexFilters__footer {
  display: none;
}

.ShopComplexFilters__section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: solid 1px #2223;
}

.ShopComplexFilters__sectionTitle {
  margin-bottom: 16px;
}

.ShopComplexFilters__checkbox {
  margin: 8px 0;
}

@media (max-width: 720px) {
  .ShopComplexFilters {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    max-height: 100vh;
    padding-top: 0;
    padding-bottom: 80px;
    margin-right: 0;
    z-index: 1000;
  }

  .ShopComplexFilters__header,
  .ShopComplexFilters__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 24px;
  }

  .ShopComplexFilters__header {
    border-bottom: solid 1px #2223;
  }

  .ShopComplexFilters__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #fff;
    border-top: solid 1px #2223;
  }

  .ShopComplexFilters__section {
    padding: 24px;
  }
}
