.DesignSection {
  margin: 16px 0;
  padding: 24px 0;
  border-bottom: solid 1px #2223;
}

.DesignSection__heading {
  margin-bottom: 8px;
}

.DesignSection__component {
  margin-top: 16px;
}

.DesignSection__selectedProduct {
  margin-top: 24px;
}
