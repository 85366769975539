.TheoristSelector {
  display: flex;
  flex-direction: column;
  max-width: calc(50vw - 80px);
}

.TheoristSelector__options {
  display: flex;
  flex-direction: row;
}

.TheoristSelector__option {
  background: none;
  outline: none;
  border: solid 2px #2223;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  flex: 1;
  cursor: pointer;
}

.TheoristSelector__option--selected {
  border: solid 2px #0f52ba;
}

.TheoristSelector__option:not(:last-child) {
  margin-right: 16px;
}

.TheoristSelector__manualTheorist {
  display: flex;
  flex-direction: row;
}

.TheoristSelector__profile {
  flex: 1;
  margin-right: 16px;
}

@media (max-width: 1080px) {
  .TheoristSelector__options {
    flex-direction: column;
  }

  .TheoristSelector__option:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 720px) {
  .TheoristSelector {
    max-width: 100%;
  }
}
