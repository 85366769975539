.Text {
  line-height: 1.5;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.Text--body1 {
}

.Text--body2 {
  font-size: 14px;
  opacity: 0.5;
}

.Text--body3 {
  font-size: 14px;
}

.Text--body4 {
  opacity: 0.5;
}

.Text--body5 {
  font-size: 12px;
  opacity: 0.5;
}

.Text--body6 {
  font-size: 12px;
}

.Text--display1 {
  font-size: 64px;
  font-weight: 800;
  line-height: 1.2;
}

.Text--display2 {
  font-size: 32px;
  font-weight: 600;
}

.Text--display3 {
  font-size: 24px;
  font-weight: 500;
}

.Text--heading1 {
  font-size: 32px;
  font-weight: 800;
}

.Text--heading2 {
  font-size: 24px;
  font-weight: 600;
}

.Text--heading3 {
  font-weight: 600;
}

.Text--heading4 {
  font-size: 14px;
  font-weight: 600;
}

.Text--heading5 {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.5;
}

.Text--heading6 {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.5;
}

.Text--heading7 {
  font-size: 12px;
  font-weight: 600;
}

.Text--label1 {
  font-size: 14px;
  font-weight: 700;
}

.Text--label2 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.Text--label3 {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.Text--label4 {
  font-size: 24px;
  font-weight: 600;
}

.Text--label5 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.Text--label6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.5;
}

.Text--label7 {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
