.ProductCategories__selection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
}

.ProductCategories__category {
  margin: 8px 8px 0 0;
}

.ProductCategories__subSelection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ProductCategories__subSelection > * {
  width: calc(50% - 8px);
  margin-top: 16px;
}

.ProductCategories__subSelection > *:nth-child(even) {
  margin-left: 16px;
}
