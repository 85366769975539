.LoadingWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: none;
  align-items: center;
  justify-content: center;
  background: #fff8;
}

.LoadingWrapper--loading {
  display: flex;
}
