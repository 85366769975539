.TopTheoristReward {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  border-top: solid 1px #2223;
}

.TopTheoristReward__iconWrapper {
  width: 32px;
  height: 32px;
  padding: 8px;
  background: #222;
  border-radius: 50%;
  box-sizing: border-box;
}

.TopTheoristReward__icon {
  width: 16px;
  height: 16px;
  color: #ffffff;
}

.TopTheoristReward__title {
  margin: 8px 0 4px;
}
