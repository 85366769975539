.Accessibility button:focus,
.Accessibility a:focus {
  outline: none;
  box-shadow: none;
}

.Accessibility--keyboard button:focus {
  box-shadow: 0 0 0 4px #fff, 0 0 0 5px #222;
}

.Accessibility--keyboard a:focus {
  border-radius: 1px;
  box-shadow: 0 0 0 4px #fff, 0 0 1px 5px #2228;
}
