.Grid {
  display: flex;
  flex-direction: column;
}

.Grid__title {
  margin-bottom: 24px;
}

.Grid__title--withSubtitle {
  margin-bottom: 8px;
}

.Grid__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Grid__pagination {
  margin: 24px;
}
