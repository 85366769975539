.OrderPipelineStepCreator__selectedTheorist {
  border-bottom: solid 1px #2223;
}

.OrderPipelineStepCreator__profileCard {
  max-width: calc(100vw - 96px);
  margin: 16px 0;
}

.OrderPipelineStepCreator__submit {
  display: flex;
  flex-direction: row-reverse;
  padding: 16px 0;
}
