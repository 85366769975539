.DesignReview__speechBubble {
  position: relative;
  background: #2221;
  border-radius: 24px;
  padding: 32px 32px 32px 48px;
  margin-bottom: 16px;
}

.DesignReview__speechBubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 32px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: #2221;
  border-bottom: 0;
  margin-left: -8px;
  margin-bottom: -8px;
}

.DesignReview__quotationMark {
  position: absolute;
  top: 0;
  left: 16px;
  color: #ff741788;
}

.DesignReview__profile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DesignReview__profileText {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.DesignReview__profileSpacer {
  flex: 1;
}

.DesignReview__profileName {
  text-decoration: none;
  color: #222;
}

.DesignReview__profileName:hover {
  opacity: 0.5;
}
