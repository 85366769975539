.ReviewEditor {
  display: flex;
  flex-direction: row;
}

.ReviewEditor__info {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 24px;
}

.ReviewEditor__image {
  margin-right: 16px;
}

.ReviewEditor__link {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
}

.ReviewEditor__link:hover {
  text-decoration: underline;
}

.ReviewEditor__review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.ReviewEditor__title {
  margin: 4px 0 8px;
}

.ReviewEditor__attr {
  margin-top: 4px;
}

.ReviewEditor__theorist {
  margin-bottom: 16px;
}

.ReviewEditor__ratings {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReviewEditor__ratingStars {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReviewEditor__ratingText {
  margin-left: 8px;
}

.ReviewEditor__star {
  margin-right: 4px;
  color: #2223;
  cursor: pointer;
}

.ReviewEditor__star--active {
  color: #fda50f;
}

.ReviewEditor__body {
  width: 100%;
  height: 96px;
  min-height: 96px;
  margin-top: 16px;
}

@media (max-width: 1080px) {
  .ReviewEditor {
    flex-direction: column;
  }
}
