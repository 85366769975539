.SelectedProduct {
  display: flex;
  flex-direction: column;
}

.SelectedProduct__detail {
  display: flex;
  flex-direction: row;
}

.SelectedProduct__image {
  width: 80px;
  height: 100px;
  margin-right: 24px;
}

.SelectedProduct__text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.SelectedProduct__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.SelectedProduct__link {
  color: inherit;
  text-decoration: none;
}

.SelectedProduct__link:hover {
  text-decoration: underline;
}

.SelectedProduct__removeButton {
  opacity: 0.5;
}

.SelectedProduct__subtitle {
  margin-top: 8px;
}

.SelectedProduct__configurator {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.SelectedProduct__theorist {
  margin: 12px 0 24px;
}

.SelectedProduct__pocketDisclaimer {
  margin: 8px 0;
}

@media (max-width: 720px) {
  .SelectedProduct__detail {
    flex-direction: column;
  }

  .SelectedProduct__image {
    width: 100%;
    padding-bottom: 125%;
    margin-bottom: 24px;
  }
}
