.DashProductEdit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DashProductEdit__head {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

.DashProductEdit__body {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.DashProductEdit__leftColumn {
  flex: 2;
  margin-right: 40px;
}

.DashProductEdit__rightColumn {
  flex: 1;
}

.DashProductEdit__title {
  margin: 0 16px;
}

.DashProductEdit__flexSpace {
  flex: 1;
}

.DashProductEdit__preview {
  margin: 0 0 0 16px;
}

.DashProductEdit__previewIcon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.DashProductEdit__titleInput,
.DashProductEdit__imageUpload,
.DashProductEdit__productCustomizer,
.DashProductEdit__productInventory,
.DashProductEdit__status {
  width: 100%;
  margin: 24px 0;
}

.DashProductEdit__pricing {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.DashProductEdit__priceInput {
  width: calc(50% - 8px);
  margin: 16px 0;
}

.DashProductEdit__priceInput:first-child {
  margin-right: 16px;
}

.DashProductEdit__fees {
  display: flex;
  flex-direction: row;
  padding: 24px 40px;
  border: solid 1px #2223;
  border-radius: 12px;
}

.DashProductEdit__statusMessage {
  margin: 16px 0;
}

.DashProductEdit__statusButton {
  width: 100%;
}

.DashProductEdit__vendor {
  margin: 8px 0 24px;
}

.DashProductEdit__collaborators {
  margin-bottom: 24px;
}

.DashProductEdit__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 24px;
  margin-top: 24px;
  border-top: solid 1px #2223;
}

.DashProductEdit__footer > *:not(:first-child),
.DashProductEdit__dialogActions > *:not(:first-child) {
  margin-left: 8px;
}

.DashProductEdit__footerSpace {
  flex: 1;
}

.DashProductEdit__dialogActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 16px;
  margin-top: 24px;
  border-top: solid 1px #2223;
}

@media (max-width: 720px) {
  .DashProductEdit__head {
    flex-direction: column;
    align-items: flex-start;
  }

  .DashProductEdit__title {
    margin: 8px 0;
  }

  .DashProductEdit__preview {
    margin: 16px 0 0 0;
  }

  .DashProductEdit__body {
    flex-direction: column;
  }

  .DashProductEdit__leftColumn {
    margin-right: 0;
  }

  .DashProductEdit__fees {
    padding: 24px 0;
    border: none;
    border-top: solid 1px #2223;
    border-bottom: solid 1px #2223;
    border-radius: 0;
  }
}
