.DashError__contact {
  margin-top: 24px;
}

.DashError__contact > a {
  color: #222;
  font-weight: 600;
}

.DashError__contact > a:hover {
  opacity: 0.5;
}
