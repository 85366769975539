.PriceLine {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PriceLine__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.PriceLine__title {
  display: inline-flex;
  width: 144px;
  min-width: 144px;
}

.PriceLine__extra {
  opacity: 0.5;
}


@media (max-width: 720px) {
  .PriceLine {
    align-items: flex-end;
  }

  .PriceLine__info {
    flex-direction: column;
    align-items: flex-start;
  }
}
