.LineItemGroup__title {
  margin: 24px 0;
}

.LineItemSection__group {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px #2223;
}
