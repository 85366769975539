.ReviewItem {
  display: flex;
  flex-direction: column;
}

.ReviewItem__customer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.ReviewItem__customerAvatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #222;
  color: #fff;
  border-radius: 50%;
  margin-right: 16px;
}

.ReviewItem__customerInfo {
  display: flex;
  flex-direction: column;
}

.ReviewItem__rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
}

.ReviewItem__rating > * {
  margin-right: 4px;
}

.ReviewItem__star {
  width: 12px;
  height: 12px;
}

.ReviewItem__star--unearned {
  color: #2223;
}
