.DashOrderList__heading {
  margin-bottom: 24px;
}

.DashOrderList__order {
  cursor: pointer;
}

.DashOrderList__order:hover {
  background: #2221;
}

.DashOrderList__link {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
}

.DashOrderList__link:hover {
  text-decoration: underline;
}

.DashOrderList__status,
.DashOrderList__assignees {
  flex-direction: column;
  align-items: flex-start;
}

.DashOrderList__table {
  width: 100%;
  margin-top: 24px;
  border-collapse: collapse;
}

.DashOrderList__th,
.DashOrderList__td {
  padding: 16px 8px;
  text-align: start;
}

.DashOrderList__td {
  border-top: solid 1px #2223;
}

.DashOrderList__infoLine {
  margin: 8px 0;
}

.DashOrderList__order--closed .DashOrderList__td--text,
.DashOrderList__order--closed .DashOrderList__link,
.DashOrderList__order--closed .DashOrderList__infoLine--text {
  font-weight: 500;
  opacity: 0.5;
}

.DashOrderList__pagination {
  margin: 24px;
}

@media (max-width: 720px) {
  .DashOrderList__status {
    flex-direction: row;
    align-items: center;
  }
}
