.Dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Dropdown__label {
  text-align: start;
  margin-bottom: 8px;
}

.Dropdown__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.Dropdown__overlay--closed {
  display: none;
}

.Dropdown__selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 8px;
  border: solid 1px #2228;
  cursor: pointer;
  background: none;
  outline: none;
}

.Dropdown__selected:focus {
  border: solid 1px #222;
  box-shadow: #222 0 0 0 2px inset !important;
}

.Dropdown__selectedLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Dropdown__arrow {
  transform: rotate(0deg);
  transition: transform 0.2s;
}

.Dropdown__arrow--open {
  transform: rotate(180deg);
}

.Dropdown__items {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  max-height: 216px;
  overflow-y: auto;
  background: #fff;
  border-radius: 8px;
  border: solid 1px #2223;
  box-shadow: 0 4px 4px #2223;
}

.Dropdown__items--closed {
  display: none;
}

.DropdownItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  min-height: 48px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.DropdownItem:focus,
.DropdownItem:hover {
  background: #2221;
  box-shadow: none !important;
}

.DropdownItem--selected {
  font-weight: 700;
}
