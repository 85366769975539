.Shop__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  min-height: 80px;
  width: 100vw;
  margin: 0 -80px;
  padding: 0 80px;
  box-sizing: border-box;
  background: #fff;
  border-bottom: solid 1px #2223;
}

.Shop__headerExtra {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Shop__reset {
  margin-right: 16px;
}

.Shop__resultSize {
  margin: 8px 16px 8px 0;
}

.Shop__headerExtraSpace {
  flex: 1;
}

.Shop__filterToggle {
  margin: 8px 0;
}

.Shop__body {
  display: flex;
  flex-direction: row;
}

.Shop__content {
  flex: 1;
}

.Shop__empty {
  margin: 40px 0;
}

.Shop__modules--loading {
  display: none;
}

@media (min-width: 1600px) {
  .Shop__header {
    margin: 0 calc(720px - 50vw);
    padding: 0 calc(50vw - 720px);
  }
}

@media (max-width: 1080px) {
  .Shop__header {
    margin: 0 -40px;
    padding: 0 40px;
  }
}

@media (max-width: 720px) {
  .Shop__header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 -24px;
    padding: 16px 24px 0 24px;
  }

  .Shop__headerExtra {
    width: calc(100vw - 48px);
  }
}
