.ProductInfo__title {
  margin: 4px 0;
}

.ProductInfo__subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.ProductInfo__link {
  text-decoration: underline;
  cursor: pointer;
}

.ProductInfo__divider {
  margin: 0 8px;
}

.ProductInfo__star {
  width: 12px;
  margin: 0 8px 0 4px;
  color: #fda50f;
}

.ProductInfo__extras {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}

.ProductInfo__highlight {
  margin-right: 16px;
}
