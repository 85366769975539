.StatusIndicator {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
  padding: 0 12px;
  margin: 4px;
  border-radius: 12px;
  background: #2224;
  text-transform: capitalize;
  white-space: nowrap;
}

.StatusIndicator--green {
  background: #00a86b44;
}

.StatusIndicator--blue {
  background: #0f52ba44;
}

.StatusIndicator--gray {
  background: #22222244;
}

.StatusIndicator--yellow {
  background: #fce20544;
}

.StatusIndicator--red {
  background: #b2222244;
}
