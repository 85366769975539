.OrderPipelinePackage {
  display: flex;
  flex-direction: row;
}

.OrderPipelinePackage__icon {
  min-width: 20px;
}

.OrderPipelinePackage__text {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.OrderPipelinePackage__inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.OrderPipelinePackage__inputs > * {
  width: calc(50% - 8px);
}

.OrderPipelinePackage__inputs > *:nth-child(odd) {
  margin: 0 16px 16px 0;
}

.OrderPipelinePackage__delivery {
  margin-top: 8px;
}

.OrderPipelinePackage__delivery--yellow {
  color: #fda50f;
  opacity: 1;
}

.OrderPipelinePackage__delivery--red {
  color: #b22222;
}

.OrderPipelinePackage__submit {
  display: flex;
  flex-direction: row-reverse;
  padding: 16px 0;
}
