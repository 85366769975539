.DesignGuarantee__teaser {
  display: flex;
  flex-direction: row;
}

.DesignGuarantee__icon {
  color: #0f52ba;
  min-width: 24px;
  margin: 4px 16px 0 0;
}

.DesignGuarantee__link {
  font-size: inherit;
  font-weight: 600;
  background: none;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: underline;
}
