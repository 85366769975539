.TheoristLookup {
  position: relative;
}

.TheoristLookup__input {
  margin-left: 40px;
}

.TheoristLookup__icon {
  position: absolute;
  top: 16px;
  left: 16px;
}

.TheoristLookup__results {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-height: 216px;
  overflow-y: auto;
  background: #fff;
  border-radius: 8px;
  border: solid 1px #2223;
  box-shadow: 0 4px 4px #2223;
}

.TheoristLookup__result {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  min-height: 48px;
  padding: 0 16px;
  white-space: nowrap;
  overflow: hidden;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.TheoristLookup__result:focus,
.TheoristLookup__result:hover {
  background: #2221;
  box-shadow: none !important;
}
