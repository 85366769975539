.Legal {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 1000px;
}

.Legal__links {
  display: flex;
  flex-direction: column;
  width: 160px;
}

.Legal__link {
  margin-bottom: 16px;
  font-size: 14px;
  color: #222;
  text-decoration: none;
}

.Legal__link--active {
  font-weight: 700;
}

.Legal__link:not(.Legal__link--active):hover {
  color: #2228;
}

.Legal__content {
  display: flex;
  flex: 1;
  height: 100vh;
  min-height: 100vh;
  border-radius: 8px;
  border: solid 1px #2223;
  box-shadow: 0 4px 4px #2223;
}

@media (max-width: 1080px) {
  .Legal {
    width: 100%;
  }
}

@media (max-width: 720px) {
  .Legal {
    flex-direction: column;
  }

  .Legal__links {
    width: 100%;
    margin-bottom: 24px;
    flex-direction: row;
    justify-content: center;
  }

  .Legal__link {
    margin: 0 12px;
    padding: 8px 4px;
    text-align: center;
  }

  .Legal__link--active {
    border-bottom: solid 2px #222;
  }
}
