.RichText {
  overflow: auto;
}

.RichText a {
  color: inherit;
  font-weight: 600;
}

.RichText a:hover {
  opacity: 0.5;
}
