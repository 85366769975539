.Shell {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.Shell__banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 12px 24px;
  margin-bottom: 8px;
  box-sizing: border-box;
  z-index: 1;
  background: #2221;
}

.Shell__container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
}

.Shell__container--drawerRight {
  flex-direction: row-reverse;
}

.Shell__drawer {
  position: sticky;
  top: 80px;
  height: calc(100vh - 80px);
  background: #fff;
  border-right: solid 1px #2223;
}

.Shell__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 40px 80px;
}

.Shell__content {
  width: 100%;
  max-width: 1440px;
  flex: 1;
  box-sizing: border-box;
}

.Shell__footer {
  width: 100%;
  max-width: 1440px;
  margin-top: 80px;
  box-sizing: border-box;
}

@media (max-width: 1080px) {
  .Shell__drawer {
    position: static;
    height: 100%;
  }

  .Shell__main {
    padding: 40px;
  }
}

@media (max-width: 720px) {
  .Shell__main {
    padding: 40px 24px;
  }
}
