.Home__banner {
  background: #b22222;
  color: #fff;
  font-size: 14px;
}

.Home__bannerLink {
  margin: 0 4px;
  color: #fff;
  font-weight: 600;
}

.Home__bannerLink:hover {
  opacity: 0.5;
}


.Home__landing {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1440px;
  height: 680px;
}

.Home__landingDesktopImage {
  position: absolute;
  top: -40px;
  right: -80px;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: top left no-repeat;
  background-size: 1200px;
}

.Home__landingTagline {
  width: 512px;
  margin: 40px 0 24px;
}

.Home__landingSubtext {
  width: 400px;
  margin-bottom: 40px;
}

.Home__landingButton {
  width: 240px;
  margin-bottom: 24px;
  border-radius: 24px;
}

.Home__module {
  margin-top: 80px;
  padding-top: 40px;
  border-top: solid 1px #2223;
}

.Home__section {
  margin-top: 80px;
  padding-top: 80px;
  border-top: solid 1px #2223;
}

.Home__sectionContent {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.Home__sectionTitle {
  font-weight: 700;
}

.Home__sectionItem {
  flex: 1;
}

.Home__sectionSpacer {
  width: 80px;
  height: 40px;
}

.Home__differentiatorTitle {
  margin: 16px 0;
}

.Home__landingTagline,
.Home__landingSubtext,
.Home__landingButton {
  animation: 1.5s ease 0s normal forwards 1;
}

.Home__landingTagline {
  animation-name: fadeInAndUp;
}

.Home__landingSubtext,
.Home__landingButton {
  animation-name: fadeIn;
}

@keyframes fadeInAndUp {
  0% { opacity: 0; transform: translateY(25%); }
  33% { opacity: 0; transform: translateY(25%); }
  66% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  66% { opacity: 0; }
  100% { opacity: 1; }
}

@media (min-width: 1600px) {
  .Home__landingDesktopImage {
    right: 0;
  }
}

@media (min-width: 1200px) {
  .Home__landingDesktopImage {
    background: top right no-repeat;
    background-size: 1200px;
  }
}

@media (max-width: 1080px) {
  .Home__landingDesktopImage {
    right: -40px;
  }

  .Home__sectionSpacer {
    width: 40px;
  }
}

@media (max-width: 720px) {
  .Home__landing {
    height: auto;
  }

  .Home__landingDesktopImage {
    display: none;
  }

  .Home__landingMobileImage {
    width: 100vw;
    height: 75vw;
    background: no-repeat;
    background-size: 100vw;
    margin: -48px -24px 0 -24px;
  }

  .Home__landingTagline {
    width: calc(100% - 32px);
    margin: 24px 0;
    font-size: 48px;
  }

  .Home__landingSubtext {
    width: calc(100% - 32px);
    margin-bottom: 24px;
  }

  .Home__landingButton {
    width: 100%;
  }

  .Home__sectionTitle {
    font-size: 24px;
  }

  .Home__sectionContent {
    flex-direction: column;
  }
}
