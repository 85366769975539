.OrderPipelineStep__addressInfo {
  display: flex;
  flex-direction: row;
  margin: 16px 0;
}

.OrderPipelineStep__address {
  flex: 1;
}

.OrderPipelineStep__stats {
  display: flex;
  flex-direction: column;
}

.OrderPipelineStep__statLine {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OrderPipelineStep__statExtra {
  margin-left: 4px;
}

.OrderPipelineStep__statExtra--good {
  color: #116547;
}

.OrderPipelineStep__statExtra--bad {
  color: #b22222;
}

.OrderPipelineStep__assigneeInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  padding-top: 16px;
  border-top: solid 1px #2223;
}

.OrderPipelineStep__updateStep {
  opacity: 0.5;
}

.OrderPipelineStep__selectedRate {
  height: 80px;
  min-height: 80px;
}

.OrderPipelineStep__rateItem {
  height: 64px !important;
  min-height: 64px !important;
}

.OrderPipelineStep__rate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.OrderPipelineStep__input {
  margin: 8px 0;
}

.OrderPipelineStep__submit {
  display: flex;
  flex-direction: row-reverse;
  padding: 16px 0;
}

@media (max-width: 720px) {
  .OrderPipelineStep__addressInfo {
    flex-direction: column;
  }

  .OrderPipelineStep__address {
    margin: 8px 0;
  }
}
