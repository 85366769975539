.DashMetricCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #2221;
  border-radius: 8px;
  padding: 24px;
  height: 216px;
}

.DashMetricCard__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
  width: 100%;
}

.DashMetricCard__divider {
  flex: 1;
}

.DashMetricCard__unit {
  height: 16px;
  margin-left: 8px;
}

.DashMetricCard__help {
  padding: 0 4px;
  border-radius: 50%;
  background: none;
  outline: none;
  border: solid 1px #222;
  opacity: 0.7;
  cursor: pointer;
}

.DashMetricCard__criteria {
  margin-top: 4px;
}

.DashMetricCard__status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DashMetricCard__statusIcon {
  width: 8px;
  height: 8px;
  color: #fff;
}

.DashMetricCard__statusIconCircle {
  background: #222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 8px;
}

.DashMetricCard__statusIconCircle--achieved {
  background: #00a86b;
}


.DashMetricCards__dialogDismiss {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  margin-top: 16px;
  border-top: solid 1px #2223;
}