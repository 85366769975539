.DashNextSteps__quickLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 24px 0;
}

.DashNextSteps__option {
  display: flex;
  flex-direction: row;
  width: calc(50% - 40px);
  margin: 24px 40px 24px 0;
}

.DashNextSteps__optionIcon {
  width: 24px;
  min-width: 24px;
  margin: 4px 16px 0 0;
  opacity: 0.5;
}

.DashNextSteps__optionContent {
  display: flex;
  flex-direction: column;
}

.DashNextSteps__optionDescription {
  margin: 8px 0;
}

.DashNextSteps__optionLinks {
  display: flex;
  flex-direction: row;
}

.DashNextSteps__optionLink {
  margin-right: 16px;
}

.DashNextSteps__optionLinkIcon {
  width: 16px;
  min-width: 16px;
  margin-right: 8px;
  opacity: 0.5;
}

@media (max-width: 720px) {
  .DashNextSteps__option {
    width: 100%;
    margin: 24px 0;
    padding-top: 24px;
    border-top: solid 1px #2223;
  }
}
