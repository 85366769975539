.DashOrderDetail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DashOrderDetail__head {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.DashOrderDetail__head--error {
  align-items: center;
  margin-bottom: 24px;
}

.DashOrderDetail__errorTitle {
  margin: 0 16px;
}

.DashOrderDetail__headBack {
  margin-bottom: 8px;
}

.DashOrderDetail__headInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}

.DashOrderDetail__titleStatus {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
}

.DashOrderDetail__title {
  margin-right: 16px;
}

.DashOrderDetail__body {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.DashOrderDetail__leftColumn {
  flex: 2;
  margin-right: 40px;
}

.DashOrderDetail__rightColumn {
  flex: 1;
}

.DashOrderDetail__section {
  margin: 24px 0;
}

.DashOrderDetail__adminLine {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.DashOrderDetail__adminButton {
  display: flex;
  flex-direction: row-reverse;
}

.DashOrderDetail__footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
  margin-top: 24px;
  border-top: solid 1px #2223;
}

.DashOrderDetail__automation {
  margin: 8px 0;
}

@media (max-width: 720px) {
  .DashOrderDetail__head {
    flex-direction: column;
    align-items: flex-start;
  }

  .DashOrderDetail__headInfo {
    margin-left: 0;
  }

  .DashOrderDetail__errorTitle {
    margin: 8px 0;
  }

  .DashOrderDetail__body {
    flex-direction: column;
  }

  .DashOrderDetail__leftColumn {
    margin-right: 0;
  }
}
