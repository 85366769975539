.Product__content {
  display: flex;
  flex-direction: row;
  max-width: calc(100vw - 48px);
}

.Product__content > * {
  flex: 1;
}

.Product__info {
  margin-bottom: 24px;
}

.Product__images {
  margin-right: 40px;
}

.Product__imageSelector {
  position: sticky;
  top: 80px;
}

.Product__configurator {
  margin-bottom: 24px;
}

.Product__theorist {
  margin: 40px 0;
}

.Product__shippingInfo {
  margin: 24px 0;
}

.Product__order {
  width: 300px;
  margin: 24px 0;
}

.Product__description {
  margin: 40px 0;
}

.Product__promise {
  margin: 40px 0;
}

.Product__reviews {
  margin-top: 40px;
  padding-top: 40px;
  border-top: solid 1px #2223;
}

@media (max-width: 720px) {
  .Product__content {
    flex-direction: column;
  }

  .Product__images {
    margin-right: 0;
  }

  .Product__order {
    width: 100%;
  }
}
