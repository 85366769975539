.DashProductList__tabs {
  margin: 24px 0;
}

.DashProductList__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.DashProductList__product {
  cursor: pointer;
}

.DashProductList__product:hover {
  background: #2221;
}

.DashProductList__productInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DashProductList__productInfoExtra {
  display: none !important;
  margin-top: 8px;
}

.DashProductList__link {
  color: inherit;
  text-decoration: none;
}

.DashProductList__link:hover {
  text-decoration: underline;
}

.DashProductList__table {
  width: 100%;
  margin-top: 24px;
  border-collapse: collapse;
}

.DashProductList__th,
.DashProductList__td {
  padding: 16px 8px;
  text-align: start;
}

.DashProductList__td {
  border-top: solid 1px #2223;
}

.DashProductList__pagination {
  margin: 24px;
}

@media (max-width: 720px) {
  .DashProductList__productInfoExtra {
    display: block !important;
  }

  .DashProductList__th {
    display: none;
  }

  .DashProductList__td--extra {
    display: none;
  }
}
