.AppBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 80px;
  min-height: 80px;
  padding: 0 24px;
  background: #fff;
  box-sizing: border-box;
}

.AppBar--sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

.AppBar--padded {
  padding: 0 80px;
}

.AppBar--sticky.AppBar--scrolling {
  border-bottom: solid 1px #2223;
  box-shadow: 0 1px 12px #2223;
}

.AppBar__content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AppBar__content--maxWidth {
  max-width: 1440px;
}

.AppBar__content > * {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AppBar__contentMiddle {
  flex: 1;
  margin: 0 16px;
  overflow-x: auto;
}

.AppBar__logo {
  height: 50px;
  margin-top: 4px;
}

.AppBar__menuButton--left {
  margin-right: 16px;
}

.AppBar__menuButton--right {
  margin-left: 16px;
}

.AppBar__cartLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 16px;
  color: #222;
  background: none;
  border: none;
  border-radius: 24px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #2223;
  cursor: pointer;
}

.AppBar__cartLink--filled {
  color: #fff;
  background: linear-gradient(#fda50f, #ff7417);
  box-shadow: none;
}

.AppBar__cartLink:focus,
.AppBar__cartLink:hover {
  box-shadow: inset 0 0 0 1px #2223, 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}

.AppBar__cartIcon {
  position: relative;
  padding: 8px 16px 8px 0;
}

@media (max-width: 1080px) {
  .AppBar {
    height: 56px;
    min-height: 56px;
    padding: 0 16px;
    border-bottom: solid 1px #2223;
  }

  .AppBar__logo {
    height: 40px;
  }
}
