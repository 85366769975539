.Footer {
  display: flex;
  flex-direction: column;
  border-top: solid 1px #2223;
}

.Footer__logo {
  display: none;
  height: 30px;
}

.Footer__links {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0 8px;
}

.Footer__social {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
}

.Footer__flex {
  flex: 1;
}

.Footer__spacer {
  margin: 0 8px;
}

.Footer__link {
  margin: 8px 0;
  color: #222;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

.Footer__link:hover {
  opacity: 0.5;
  text-decoration: underline;
}

.Footer__link--social {
  margin: 0 8px;
}

@media (max-width: 640px) {
  .Footer__logo {
    display: block;
  }

  .Footer__icon {
    display: none;
  }

  .Footer__links {
    flex-direction: column;
    align-items: flex-start;
  }

  .Footer__spacer {
    display: none;
  }
}
