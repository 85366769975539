.AddressInput {
  display: flex;
  flex-direction: column;
}

.AddressInput__input {
  margin: 8px;
}

.AddressInput__row {
  display: flex;
  flex-direction: row;
}

.AddressInput__row > * {
  flex: 1;
}

.pac-container {
  border-radius: 8px;
}

.pac-container::after {
  margin: 8px;
}

.pac-icon {
  display: none;
}

.pac-item {
  padding: 8px 16px;
}

@media (max-width: 1080px) {
  .AddressInput__row {
    flex-direction: column;
  }
}
