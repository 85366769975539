.ProductDelivery {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ProductDelivery__icon {
  min-width: 24px;
  margin-right: 12px;
}

.ProductDelivery__time {
  font-weight: 700;
}
