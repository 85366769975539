.CustomerInfo__sectionTitle {
  margin: 24px 0;
}

.CustomerInfo__section {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px #2223;
}

.CustomerInfo__section:last-child {
  border-bottom: none;
}

.CustomerInfo__line {
  margin: 8px 0;
}
