.SiteLinks {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
}

.SiteLinks__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;
  color: #222;
  text-decoration: none;
}

.SiteLinks__linkUnderline {
  background: #222;
  width: 24px;
  height: 2px;
  margin-top: 4px;
  border-radius: 1px;
  transform: scaleX(0);
  transition: transform 50ms;
}

.SiteLinks__link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.SiteLinks__link:focus .SiteLinks__linkTitle,
.SiteLinks__link:hover .SiteLinks__linkTitle {
  opacity: 0.5;
}

.SiteLinks__link:focus .SiteLinks__linkUnderline,
.SiteLinks__link:hover .SiteLinks__linkUnderline {
  transform: scaleX(1);
}

.SiteLinks__theoristButton {
  position: absolute;
  right: 8px;
}

.TheoristButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  max-width: 240px;
  border-radius: 24px !important;
  padding: 0 16px;
  text-decoration: none;
}

.TheoristButton:focus,
.TheoristButton:hover,  {
  border-radius: 24px !important;
}

.TheoristButton__image {
  margin: 0 12px 0 -8px;
}

.TheoristButton__icon {
  width: 16px;
  height: 16px;
  margin: 0 16px 0 4px;
}

.TheoristButton__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SiteDrawer {
  width: 240px;
  background: #fff;
  overflow-y: auto;
}

.SiteDrawer__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.SiteDrawer__section {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  border-top: solid 1px #2223;
  border-bottom: solid 1px #2223;
}

.SiteDrawer__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #222;
  text-decoration: none;
  padding: 16px 8px;
}

.SiteDrawer__link:focus,
.SiteDrawer__link:hover {
  outline: none !important;
  box-shadow: none !important;
  opacity: 0.5;
}

.SiteDrawer__linkIcon {
  width: 24px;
  margin: 2px 16px 0 0;
  color: #2228;
}

.SiteDrawer__theoristButton {
  margin: 24px 8px 0 8px;
  justify-content: flex-start;
}

@media (max-width: 1260px) {
  .SiteLinks {
    justify-content: flex-start;
    padding: 0 24px;
  }
}

.ComplexSiteBar__bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ComplexSiteBar__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
  color: #222;
  text-decoration: none;
}

.ComplexSiteBar__sectionUnderline {
  background: #222;
  width: 24px;
  height: 2px;
  margin-top: 4px;
  border-radius: 1px;
  transform: scaleX(0);
  transition: transform 50ms;
}

.ComplexSiteBar__section--active {
  color: #2228;
  outline: none !important;
  box-shadow: none !important;
}

.ComplexSiteBar__section--active .ComplexSiteBar__sectionUnderline {
  transform: scaleX(1);
}

.ComplexSiteBar__menu {
  position: fixed;
  top: 96px;
  left: calc(50vw - 240px);
  width: 480px;
  padding: 24px;
  border-radius: 12px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 1px 12px #2223;
  z-index: 1000;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s, visibility 0.2s;
}

.ComplexSiteBar__overlay {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 80px);
  background: #fff8;
  z-index: 100;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s, visibility 0.3s;
}

.ComplexSiteBar__menu--closed,
.ComplexSiteBar__overlay--closed {
  opacity: 0;
  visibility: hidden;
}

.ComplexSiteDrawer {
  width: 240px;
  background: #fff;
  overflow-y: auto;
}

.ComplexSiteDrawer__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.ComplexSiteDrawer__section {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-top: solid 1px #2223;
}

.ComplexSiteDrawer__sectionTitle {
  margin-bottom: 16px;
}

.ComplexSiteLink {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0;
  color: #222;
  text-decoration: none;
}

.ComplexSiteLink__icon {
  width: 24px;
  margin: 2px 16px 0 0;
  color: #2228;
  transition: color 0.3s;
}

.ComplexSiteLink__description {
  transition: opacity 0.3s;
}

.ComplexSiteLink__arrow {
  height: 12px;
  margin: 4px 0 0 8px;
  opacity: 0;
  transform: translateX(-4px);
  transition: opacity 0.1s, transform 0.3s;
}

.ComplexSiteLink:hover .ComplexSiteLink__description {
  opacity: 1;
}

.ComplexSiteLink:hover .ComplexSiteLink__arrow {
  opacity: 1;
  transform: translateX(0);
}

.ComplexSiteLink--slim {
  align-items: center;
}

.ComplexSiteLink--slim .ComplexSiteLink__title {
  font-size: 14px;
}

.ComplexSiteLink--slim:hover .ComplexSiteLink__title {
  opacity: 0.5;
}
