.ProductVendor {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: 36px;
  color: #222;
  text-decoration: none;
  overflow: hidden;
}

.ProductVendor__image {
  position: relative;
}

.ProductVendor__eliteIcon {
  position: absolute;
  bottom: 0;
  right: -2px;
  width: 12px;
  height: 18px;
  color: #ff7417;
}

.ProductVendor__name {
  margin-left: 8px;
}

.ProductVendor:hover .ProductVendor__name {
  opacity: 0.5;
}

.ProductVendor__location {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ProductVendor__divider {
  margin: 0 4px;
}
