.ProductImageUpload {
  display: flex;
  flex-direction: column;
}

.ProductImageUpload__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.ProductImageUpload__body {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ProductImageUpload__column {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 12px;
}

.ProductImageUpload__column:last-child {
  margin: 0;
}

.ProductImageUpload__row {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 12px;
}

.ProductImageUpload__row:last-child {
  margin: 0;
}

.ProductImageUpload__cell {
  position: relative;
  width: 100%;
  height: 100%;
  margin-right: 12px;
  box-sizing: border-box;
}

.ProductImageUpload__cell > * {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.ProductImageUpload__cell:last-child {
  margin: 0;
}

.ProductImageUpload__column > .ProductImageUpload__cell {
  padding-bottom: 125%;
}

.ProductImageUpload__removeImage {
  display: none;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  color: #fff;
}

.ProductImageUpload__cell:focus > .ProductImageUpload__imageOverlay,
.ProductImageUpload__cell:hover > .ProductImageUpload__imageOverlay {
  background: linear-gradient(#0008, #0000);
}

.ProductImageUpload__cell:focus > .ProductImageUpload__removeImage,
.ProductImageUpload__cell:hover > .ProductImageUpload__removeImage {
  display: flex;
}

.ProductImageUpload__addImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  border: dashed 2px #2228;
  cursor: pointer;
}


.ProductImageUpload__addImage--dragging,
.ProductImageUpload__addImage:hover {
  background: #2221;
}

.ProductImageUpload__addImageText {
  text-align: center;
}

@media (max-width: 1080px) {
  .ProductImageUpload__addImage {
    padding: 0;
  }
}

@media (max-width: 720px) {
  .ProductImageUpload__body {
    flex-direction: column;
  }

  .ProductImageUpload__column {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .ProductImageUpload__cell {
    padding-bottom: 62.5%;
  }

  .ProductImageUpload__addImageText {
    display: none;
  }
}