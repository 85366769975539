.IconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: #0000;
  outline: 0;
  border: 0;
  border-radius: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.IconButton:hover {
  background: #2221;
}

.IconButton:active {
  background: #2223;
}

.IconButton:disabled {
  background: #0000;
  opacity: 0.5;
  cursor: not-allowed;
}

.IconButton__icon {
  width: 16px;
  height: 16px;
}
