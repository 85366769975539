.Design__content {
  display: flex;
  flex-direction: row;
  max-width: calc(100vw - 48px);
}

.Design__content > * {
  flex: 1;
}

.Design__images {
  margin-right: 80px;
}

.Design__imageSelector {
  position: sticky;
  top: 80px;
}

.Design__imageSelector--inactive {
  display: none;
}

.Design__heading {
  margin-bottom: 8px;
}

.Design__review {
  margin-top: 24px;
}

.Design__guarantee {
  margin-top: 40px;
}

.Design__notes {
  margin-top: 40px;
}

@media (max-width: 1080px) {
  .Design__images {
    margin-right: 40px;
  }
}

@media (max-width: 720px) {
  .Design__content {
    flex-direction: column;
  }

  .Design__images {
    display: none;
  }
}
