.SignUp {
  line-height: 1.5;
}

.SignUp__bannerLink {
  margin: 0 4px;
  color: #222;
  font-weight: 600;
}

.SignUp__bannerLink:hover {
  opacity: 0.5;
}

.SignUp__intro {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 24px;
}

.SignUp__introImage,
.SignUp__introText {
  flex: 1;
}

.SignUp__introImage {
  margin: 0 40px 0 0;
}

.SignUp__introImage > * {
  width: 100%;
  padding-bottom: 56.25%;
}

.SignUp__section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 40px;
  padding-top: 40px;
  border-top: solid 1px #2223;
}

.SignUp__sectionSpacer {
  flex: 1;
}

.SignUp__block {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 24px;
  padding: 24px 16px;
  border-radius: 12px;
  border: solid 1px #2223;
}

.SignUp__blockHeading {
  margin: 16px 8px;
}

.SignUp__blockDescription {
  margin: 4px 8px;
}

.SignUp__blockDivider {
  height: 1px;
  margin: 24px 8px;
  background: #2223;
}

.SignUp__inputRow {
  display: flex;
  flex-direction: row;
}

.SignUp__inputRow > * {
  flex: 1;
}

.SignUp__input {
  margin: 8px;
}

.SignUp__button {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 40px;
  padding-top: 24px;
  border-top: solid 1px #2223;
}

.SignUp__checkbox {
  margin: 8px;
}

.SignUp__error {
  margin: 24px;
  text-align: center;
  color: #b22222;
}

@media (max-width: 1080px) {
  .SignUp__inputRow {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .SignUp__intro {
    flex-direction: column;
  }

  .SignUp__introImage {
    width: 100%;
    margin: 0 0 40px 0;
  }

  .SignUp__section {
    flex-direction: column;
  }

  .SignUp__block {
    width: 100%;
    margin: 24px 0;
    padding: 0;
    border: none;
  }
}
