.ShopFilterToggle {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
}

.ShopFilterToggle__icon {
  margin-right: 4px;
}

.ShopFilterToggle__count {
  position: absolute;
  top: -2px;
  right: -4px;
  width: 16px;
  height: 16px;
  color: #fff;
  background: #222;
  border-radius: 12px;
  border: solid 1px #fff;
}
