.DashRewards__row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.DashRewards__item {
  width: calc(33% - 16px);
  box-sizing: border-box;
  margin-right: 16px;
  margin-top: 24px;
}

@media (max-width: 720px) {
  .DashRewards__row {
    flex-direction: column;
  }

  .DashRewards__item {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0;
  }
}
