.DashMetrics__explainer {
  margin-top: 8px;
}

.DashMetrics__row {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
}

.DashMetrics__card {
  width: calc(25% - 16px);
  box-sizing: border-box;
  margin-right: 16px;
}

@media (max-width: 900px) {
  .DashMetrics__card {
    width: calc(50% - 8px);
    margin-bottom: 16px;
  }

  .DashMetrics__card:nth-child(2n) {
    margin-right: 0;
  }
}
