.CategoryItem {
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 56.25%;
  border-radius: 12px;
  border: solid 1px #2223;
  cursor: pointer;
  overflow: hidden;
  transform: scale(1);
  transition: transform 50ms;
}

.CategoryItem__image,
.CategoryItem__overlay,
.CategoryItem__text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.CategoryItem__overlay {
  z-index: 1;
  background: radial-gradient(circle at bottom left, #000c, #0000);
  opacity: 1;
  transition: transform 100ms;
}

.CategoryItem__text {
  display: flex;
  flex-direction: column-reverse;
  padding: 24px;
  z-index: 2;
  color: #fff;
}

.CategoryItem:hover > .CategoryItem__overlay {
  opacity: 0.5;
}

.CategoryItem:active {
  transform: scale(0.95);
}
